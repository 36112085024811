import withRouter from '../modules/withRouter';
import React from 'react';

class NewHireThankYou extends React.Component {

    componentDidMount() {
        document.title = document.title + ' - Thank You';
    }

    render() {
        return (
            <>
                <section className="hero is-primary is-fullheight">
                    <div className="hero-body">
                        <div>
                            <h1 className="title is-4">Thank You!</h1>
                            <h3 className='subtitle is-4'>Your free Merkle B2B swag is on it's way!</h3>
                            <p className="content">
                                We are so excited that you are joining the team. We can't wait to create the never before in B2B with you!
                            </p>
                        </div>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(NewHireThankYou);

