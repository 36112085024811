import axios from 'axios'
import { NewHireModel } from '../models';
import Logger from '../modules/Logger';
const log = new Logger('NewHire')

class NewHire {
	constructor(data) {
		if (!data) {
			data = { ...NewHireModel }
		}

		if (typeof data !== 'object') {
			throw (new Error('You must supply data'))
		}

		for (let key in NewHireModel) {
			if (!data.hasOwnProperty(key)) {
				throw (new Error(`The data supplied is incomplete or does not match the schema (missing property '${key}'`))
			}
		}

		for (let key in data) {
			if (key !== '_id' && !NewHireModel.hasOwnProperty(key)) {
				log.warn(`The data supplied has unknown properties and may not match the required schema (extra property '${key}'`)
				//throw (new Error(`The data supplied has unknown properties and not match the schema (extra property '${key}'`))
			}
		}

		// if(!data.hasOwnProperty('id')) {
		//     throw(new Error('The supplied data MUST have an id property'))
		// }

		this._formData = { ...data }
	}

	async save() {
		let response
		try {
			const _formData = this._formData
			const url = `/api/v1/newhire/${_formData._id ? `${_formData._id}/update` : `add`}`;
			response = await axios.post(url, { newHire: this._formData }, {validateStatus: status => (status >= 200 && status < 300) || status === 422})
			if (response.status === 200 && response.data.status === "success") {
				return new NewHire({ ...response.data.result })
			} else {
				throw new Error('The request failed')
			}
		} catch (e) {			
			if (response.status === 422 && response.data.status === "duplicate") {
				return { status: "duplicate" }
			} else {
				log.error(e.message)
				throw (e)
			}
		}

	}

	static async getFormDataById(id) {
		if (id) {
			try {
				const url = `/api/v1/newhire/${id}/get`;
				const response = await axios.get(url)
				if (response.status === 200 && response.data.status === "success") {
					return new NewHire({ ...response.data.result })
				} else {
					throw new Error('The request failed')
				}
			} catch (e) {
				console.log(e.message)
				throw (e);
			}

		} else {
			console.log(`You must provide an ID to retrieve a form`)
			return false;
		}
	}

	get _id() {
		return this._formData._id
	}

	set _id(value) {
		this._formData._id = value
		return this._formData._id.value
	}

	get CareerAdvocateName() {
		return this._formData.CareerAdvocateName
	}

	set CareerAdvocateName(value) {
		this._formData.CareerAdvocateName = value
		return this._formData.CareerAdvocateName.value
	}

	get CareerAdvocateEmail() {
		return this._formData.CareerAdvocateEmail
	}

	set CareerAdvocateEmail(value) {
		this._formData.CareerAdvocateEmail = value
		return this._formData.CareerAdvocateEmail.value
	}

	get NewHireName() {
		return this._formData.NewHireName
	}

	set NewHireName(value) {
		this._formData.NewHireName = value
		return this._formData.NewHireName.value
	}

	get NewHireTitle() {
		return this._formData.NewHireTitle
	}

	set NewHireTitle(value) {
		this._formData.NewHireTitle = value
		return this._formData.NewHireTitle.value
	}

	get NewHireBU() {
		return this._formData.NewHireBU
	}

	set NewHireBU(value) {
		this._formData.NewHireBU = value
		return this._formData.NewHireBU.value
	}

	get NewHirePersonalEmail() {
		return this._formData.NewHirePersonalEmail
	}

	set NewHirePersonalEmail(value) {
		this._formData.NewHirePersonalEmail = value
		return this._formData.NewHirePersonalEmail.value
	}

	get NewHireMobileNumber() {
		return this._formData.NewHireMobileNumber
	}

	set NewHireMobileNumber(value) {
		this._formData.NewHireMobileNumber = value
		return this._formData.NewHireMobileNumber.value
	}

	get NewHireStartDate() {
		return this._formData.NewHireStartDate
	}

	set NewHireStartDate(value) {
		this._formData.NewHireStartDate = value
		return this._formData.NewHireStartDate.value
	}

	get NewHireConsent() {
		return this._formData.NewHireConsent
	}

	set NewHireConsent(value) {
		this._formData.NewHireConsent = value
		return this._formData.NewHireConsent.value
	}

	get NewHireGuide() {
		return this._formData.NewHireGuide
	}

	set NewHireGuide(value) {
		this._formData.NewHireGuide = value
		return this._formData.NewHireGuide.value
	}

	get NewHireRespondedAt() {
		return this._formData.NewHireRespondedAt
	}

	set NewHireRespondedAt(value) {
		this._formData.NewHireRespondedAt = value
		return this._formData.NewHireRespondedAt.value
	}

	get NewHireAddress1() {
		return this._formData.NewHireAddress1
	}

	set NewHireAddress1(value) {
		this._formData.NewHireAddress1 = value
		return this._formData.NewHireAddress1.value
	}

	get NewHireAddress2() {
		return this._formData.NewHireAddress2
	}

	set NewHireAddress2(value) {
		this._formData.NewHireAddress2 = value
		return this._formData.NewHireAddress2.value
	}

	get NewHireCity() {
		return this._formData.NewHireCity
	}

	set NewHireCity(value) {
		this._formData.NewHireCity = value
		return this._formData.NewHireCity.value
	}

	get NewHireState() {
		return this._formData.NewHireState
	}

	set NewHireState(value) {
		this._formData.NewHireState = value
		return this._formData.NewHireState.value
	}

	get NewHireZip() {
		return this._formData.NewHireZip
	}

	set NewHireZip(value) {
		this._formData.NewHireZip = value
		return this._formData.NewHireZip.value
	}

	get RecordCreated() {
		return this._formData.RecordCreated
	}

	set RecordCreated(value) {
		this._formData.RecordCreated = value
		return this._formData.RecordCreated.value
	}

	get NewHireEmailed() {
		return this._formData.NewHireEmailed
	}

	set NewHireEmailed(value) {
		this._formData.NewHireEmailed = value
		return this._formData.NewHireEmailed.value
	}

	get CookieVendorEmailed() {
		return this._formData.CookieVendorEmailed
	}

	set CookieVendorEmailed(value) {
		this._formData.CookieVendorEmailed = value
		return this._formData.CookieVendorEmailed.value
	}

	get SwagVendorEmailed() {
		return this._formData.SwagVendorEmailed
	}

	set SwagVendorEmailed(value) {
		this._formData.SwagVendorEmailed = value
		return this._formData.SwagVendorEmailed.value
	}

	get ELTEmailed() {
		return this._formData.ELTEmailed
	}

	set ELTEmailed(value) {
		this._formData.ELTEmailed = value
		return this._formData.ELTEmailed.value
	}

	get CareerAdvocateEmailed() {
		return this._formData.CareerAdvocateEmailed
	}

	set CareerAdvocateEmailed(value) {
		this._formData.CareerAdvocateEmailed = value
		return this._formData.CareerAdvocateEmailed.value
	}

	get StarbucksGiftCardURL() {
		return this._formData.StarbucksGiftCardURL
	}

	set StarbucksGiftCardURL(value) {
		this._formData.StarbucksGiftCardURL = value
		return this._formData.StarbucksGiftCardURL.value
	}

	get Status() {
		return this._formData.Status
	}

	set Status(value) {
		this._formData.Status = value
		return this._formData.Status.value
	}

	get createdAt() {
		return this._formData.createdAt
	}

	set createdAt(value) {
		this._formData.createdAt = value
		return this._formData.createdAt.value
	}

	get updatedAt() {
		return this._formData.updatedAt
	}

	set updatedAt(value) {
		this._formData.updatedAt = value
		return this._formData.updatedAt.value
	}

}

export default NewHire;