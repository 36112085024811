import withRouter from '../modules/withRouter';
import React from 'react';

class AdvocateDuplicate extends React.Component {

    componentDidMount() {
        document.title = document.title + ' - Error (Duplicate Entry)';
    }

    render() {
        return (
            <>
                <section className="hero is-primary is-fullheight">
                    <div className="hero-body">
                        <div>
                            <h1 className="title is-4">Whoa! Slow Down There!</h1>
                            <h3 className='subtitle is-4'>It seems like you already submitted this hire!</h3>
                            <p className="content">
                               Dont worry about it! It happens to the best of us! Maybe you are so busy you forgot you already did this, or maybe you just copy pasta'd a little to quick. Either way you can press "Back" to try again with different information.  
                            </p>
                        </div>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(AdvocateDuplicate);

