import { debug as _debug } from 'debug'
const debug = _debug('onboarding:web')
window.debug = debug
_debug.enable('onboarding:web:*')
window.debug.enabled = process.env.NODE_ENV !== 'production'

class Logger {
    constructor(namespace) {
        this._logger = debug.extend(namespace, ':')
        //eslint-disable-next-line no-console
        this._logger.log = console.log.bind(console)

        this._error = this._logger.extend('error', ':')
        //eslint-disable-next-line no-console
        this._error.log = console.error.bind(console)

        this._warn = this._logger.extend('warn', ':')
        //eslint-disable-next-line no-console
        this._warn.log = console.warn.bind(console)

        this._info = this._logger.extend('info', ':')
        //eslint-disable-next-line no-console
        this._info.log = console.info.bind(console)

        this._debug = this._logger.extend('debug', ':')
        //eslint-disable-next-line no-console
        this._debug.log = console.debug.bind(console)

        this._success = this._logger.extend('success', ':')
        //eslint-disable-next-line no-console
        this._success.log = console.log.bind(console)
    }

    async log(message) {
        this._logger(message)
    }

    async info(message) {
        this._info(message)
    }

    async warn(message) {
        this._warn(message)
    }

    async error(message) {
        this._error(message)
    }

    async debug(message) {
        this._debug(message)
    }

    async success(message) {
        this._success(message)
    }
}

export default Logger