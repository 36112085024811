import { BusinessUnit } from '../models';
import NewHire from '../modules/NewHire';
import withRouter from '../modules/withRouter';
import React from 'react';
import Logger from '../modules/Logger';
const log = new Logger('AdvocateForm')


class AdvocateForm extends React.Component {
    constructor(props) {
        super(props);

        //window.debug = _debug('AdvocateForm')
        //log.enabled = true

        this.state = {
            error: false,
            formValues: new NewHire()
        }

        this.requiredFields = [
            "CareerAdvocateName",
            "CareerAdvocateEmail",
            "NewHireName",
            "NewHireTitle",
            "NewHireBU",
            "NewHirePersonalEmail",
            //"NewHireMobileNumber",
            "NewHireStartDate",
            //"NewHireGuide",
        ]

        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        document.title = document.title + ' - Career Advocate Form';
    }

    validateForm() {
        const { formValues } = this.state;
        let isError = false
        this.requiredFields.forEach(field => {
            if (!formValues[field] || formValues.field === "") {
                isError = true
            }
        })
        this.setState({ error: isError });
        return isError
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { formValues } = this.state;
        formValues[name] = value;
        this.setState({ formValues });
    }

    async sendForm() {
        let results
        try {
            const error = this.validateForm();
            const { formValues: newHire } = this.state;
            if (!error) {
                if (newHire.CareerAdvocateName)
                    results = await newHire.save()
                if (results.status === 'duplicate') {
                    this.props.router.navigate("duplicate");
                } else {
                    log.success(`New Hire Added! New Hire Id is: ${results._id}`);
                    this.props.router.navigate("thank-you");
                }
            }
        } catch (e) {
            log.error(e);
            this.props.router.navigate("/error");
        }
    }

    render() {
        return (
            <>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <h1 className="title is-4">Start Something.</h1>
                        <h3 className='subtitle is-4'>Your new hire has accepted the position! Let's give them a strong start with Merkle B2B.</h3>
                        <p className="content">
                            Please fill out the information below for your new hire. Once you submit the form, your new hire will receive an email asking for consent from the company to send a welcome kit and first-day treat to their home. Once the new hire opts in, you will receive a confirmation email.
                        </p>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="form">
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Career Advocate Name</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder=""
                                                name="CareerAdvocateName"
                                                value={this.state.formValues.CareerAdvocateName}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Career Advocate Email Address</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="email" placeholder=""
                                                name="CareerAdvocateEmail"
                                                value={this.state.formValues.CareerAdvocateEmail}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Name</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder="" name="NewHireName"
                                                value={this.state.formValues.NewHireName}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Role/Title</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder="" name="NewHireTitle"
                                                value={this.state.formValues.NewHireTitle}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Agency</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <div className="select">
                                                <select name="NewHireBU" className='is-radiusless'
                                                    value={this.state.formValues.NewHireBU}
                                                    onChange={this.handleInputChange}>
                                                    <option></option>
                                                    <option value={BusinessUnit.CREATIVE}>Creative</option>
                                                    <option value={BusinessUnit.MEDIA}>Media</option>
                                                    <option value={BusinessUnit.RESEARCH}>Research</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Email Address</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="email" placeholder="" name="NewHirePersonalEmail"
                                                value={this.state.formValues.NewHirePersonalEmail}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Mobile Number</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="tel" placeholder=""
                                                name="NewHireMobileNumber"
                                                value={this.state.formValues.NewHireMobileNumber}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Start Date</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="email" placeholder="" name="NewHireStartDate"
                                                value={this.state.formValues.NewHireStartDate}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Employee Guide</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="email" placeholder="" name="NewHireGuide"
                                                value={this.state.formValues.NewHireGuide}
                                                onChange={this.handleInputChange} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-primary is-rounded" onClick={this.sendForm}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="box is-shadowless">
                            <div className="notification is-danger">
                                <p className="title is-6">All fields are required!</p>
                            </div>
                        </div>
                    }

                </section>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <p className="content is-small">© 2021 Merkle Inc.</p>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(AdvocateForm);
