import withRouter from '../modules/withRouter';
import React from 'react';

class AdvocateThankYou extends React.Component {

    componentDidMount() {
        document.title = document.title + ' - Career Advocate Form - Thank You';
    }
    
    render() {
        return (
            <>
                <section className="hero is-primary is-fullheight">
                    <div className="hero-body">
                        <div>
                            <h1 className="title is-4">You're Done! Thank you!</h1>
                            <h3 className='subtitle is-4'>We know as a career advocate you are really busy, but we wouldn't be able to do this without you.</h3>
                            <p className="content">
                                So seriously, thank you. Sometimes its the small things that make the biggest impact, like filling out a form, or getting some free cookies. 
                                With your help we are doing things both big and small to help our new employees feel at home and appreciated!
                            </p>
                        </div>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(AdvocateThankYou);

