// @ts-check
import NewHireModel from './NewHireModel.json';

const Status = {
  "NEW": "NEW",
  "WAITING_ON_NEW_HIRE": "WAITING_ON_NEW_HIRE",
  "COMPLETE": "COMPLETE",
  "CONSENT_DENIED": "CONSENT_DENIED"
};

const BusinessUnit = {
  "CREATIVE": "CREATIVE",
  "MEDIA": "MEDIA",
  "RESEARCH": "RESEARCH"
};

export {
  NewHireModel,
  Status,
  BusinessUnit
};