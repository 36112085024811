import NewHire from '../modules/NewHire';
import withRouter from '../modules/withRouter';
import React from 'react';
import Logger from '../modules/Logger';
const log = new Logger('NewHireForm')


class NewHireForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            formValues: new NewHire()
        }

        this.requiredFields = [
            "NewHireAddress1",
            "NewHireCity",
            "NewHireState",
            "NewHireZip",
            "NewHireConsent"
        ]


        this.handleInputChange = this.handleInputChange.bind(this);
        this.getFormRecord = this.getFormRecord.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        document.title = document.title + ' - Start Something'
        this.getFormRecord();
    }

    validateForm() {
        const { formValues } = this.state;
        let isError = false
        this.requiredFields.forEach(field => {
            if (!formValues[field] || formValues.field === "") {
                isError = true
            }
        })
        this.setState({ error: isError });
        return isError
    }

    async getFormRecord() {
        try {
            const { router: { params: { userId } } } = this.props;
            if (userId) {
                const formValues = await NewHire.getFormDataById(userId)
                if (formValues.NewHireRespondedAt && formValues.NewHireRespondedAt !== '') {
                    this.props.router.navigate('thank-you')
                }
                log.success(formValues)
                this.setState({ formValues });
            }
        } catch (e) {
            log.error(e)
            this.props.router.navigate('/record-not-found')
        }
    }

    async sendForm() {
        try {
            const error = this.validateForm()
            if (!error) {
                const { formValues: newHire } = this.state;
                log.info(newHire);
                const results = await newHire.save()
                log.success(`New Hire Updated! New Hire Id is: ${results._id}`);
                log.success(results);
                this.props.router.navigate('thank-you')
            }
        } catch (e) {
            log.error(e);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { formValues } = this.state;
        formValues[name] = value;
        this.setState({ formValues });
    }

    render() {
        const { formValues: newHireInfo } = this.state
        return (
            <>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <h1 className="title is-4">Start Something.</h1>
                        <h3 className='subtitle is-4'>Congratulations on the new position, {newHireInfo.NewHireName}!</h3>
                        <p className="content">
                            We want to give you a strong start with Merkle B2B. Please fill out the information below so that we can send you a few items to get your Merkle B2B experience kicked off!
                        </p>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="form">

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Address 1</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder=""
                                                name="NewHireAddress1"
                                                value={this.state.formValues.NewHireAddress1 ?? ""}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Address 2</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder=""
                                                name="NewHireAddress2"
                                                value={this.state.formValues.NewHireAddress2 ?? ""}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">City</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input  is-radiusless" type="text" placeholder=""
                                                name="NewHireCity"
                                                value={this.state.formValues.NewHireCity ?? ""}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">State</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <div className="select">
                                                <select name="NewHireState" className='is-radiusless'
                                                    value={this.state.formValues.NewHireState ?? ""}
                                                    onChange={this.handleInputChange}>
                                                    <option value=""></option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District Of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Zip</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input  is-radiusless" type="text" placeholder=""
                                                name="NewHireZip"
                                                value={this.state.formValues.NewHireZip ?? ""}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Mobile Number</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <input className="input is-radiusless" type="text" placeholder=""
                                                name="NewHireMobileNumber"
                                                value={this.state.formValues.NewHireMobileNumber ?? ""}
                                                onChange={this.handleInputChange}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox"
                                            name="NewHireConsent"
                                            checked={!!this.state.formValues.NewHireConsent ?? ""}
                                            onChange={this.handleInputChange} />
                                        &nbsp;I want to receive swag from Merkle B2B
                                    </label>
                                </div>
                            </div>
                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-primary" onClick={this.sendForm}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="box is-shadowless">
                            <div className="notification is-danger">
                                <p className="title is-6">You must fill in Address 1, City, State, Zip and indicate you want to receive swag for us to accept this form!</p>
                            </div>
                        </div>
                    }
                </section>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <p className="content is-small">© 2021 Merkle Inc.</p>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(NewHireForm);

