import withRouter from '../modules/withRouter';
import React from 'react';

class NoRoute extends React.Component {

    componentDidMount() {
        document.title = document.title + ' - Error - Page Not Found';
    }

    render() {
        return (
            <>
                <section className="hero is-primary is-fullheight">
                    <div className="hero-body">
                        <div>
                            <h1 className="title is-4">Something is Missing.</h1>
                            <h3 className='subtitle is-4'>The page you are looking for just isn't here! Trust us we looked.</h3>
                            <p className="content">
                                If you got here by clicking on a link in one of our emails you should let us know by emailing <a className="is-underlined" href="mailto:digital.team@merkleb2b.com">digital.team@merkleb2b.com</a>.
                                Otherwise make sure you typed in the correct address and give it another shot!
                            </p>
                        </div>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(NoRoute);

