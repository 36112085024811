import withRouter from '../modules/withRouter';
import React from 'react';

class ErrorPage extends React.Component {
    
    componentDidMount() {
        document.title = document.title + ' - Error';
    }

    render() {
        return (
            <>
                <section className="hero is-primary is-fullheight">
                    <div className="hero-body">
                        <div>
                            <h1 className="title is-4">Oh Snap!</h1>
                            <h3 className='subtitle is-4'>Something has gone horribly wrong</h3>
                            <p className="content">
                                We don't know what you did, but we are pretty sure it's your fault! No way could it EVER be OUR fault that's just preposterous. Still you should probably email us at <a className="is-underlined" href="mailto:digital.team@merkleb2b.com">digital.team@merkleb2b.com</a> and tell us how you broke it.<sup>*</sup>
                            </p>
                            <p className="content is-small"><sup>*</sup>In reality it probably is our fault; but lets just keep that between us, eh friend?</p>
                        </div>
                    </div>
                </section>
            </>)
    }
}

export default withRouter(ErrorPage);

