import AdvocateForm from './components/AdvocateForm'
import NewHireForm from './components/NewHireForm'
import AdvocateThankYou from './components/AdvocateThankYou'
import AdvocateDuplicate from './components/AdvocateDuplicate'
import NewHireThankYou from './components/NewHireThankYou'
import ErrorPage from './components/ErrorPage'
import NoRoute from './components/NoRoute'

import { Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<NoRoute />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/get-started" element={<AdvocateForm />} />
        <Route path="/get-started/thank-you" element={<AdvocateThankYou />} />
        <Route path="/get-started/duplicate" element={<AdvocateDuplicate />} />
        <Route path="/start-something/:userId" element={<NewHireForm />} />
        <Route path="/start-something/:userId/thank-you" element={<NewHireThankYou />} />
        <Route path="*" element={<NoRoute />} />
      </Routes>
    </div>
  );
}

export default App;
